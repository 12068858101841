.month-input {
  padding: 10px;
  font-size: 1rem;
  border-radius: 8px;
  border: 2px solid #4CAF50; /* green border */
  background-color: #f9f9f9;
  color: #333;
  width: 200px;
  outline: none;
  transition: all 0.3s ease;
}

.month-input:hover {
  border-color: #45a049; /* darker green on hover */
}

.month-input:focus {
  border-color: #3e8e41;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.5); /* glowing green shadow */
}

.month-input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.5); /* styling the month picker icon */
}