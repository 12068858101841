input[type="date"] {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
}

@media only screen and (min-width: 600px) {
    .h-modal-90 {
        width: 90%;
        max-width: none;
    }

    .h-modal-80 {
        width: 80%;
        max-width: none;
    }

    .h-modal-60 {
        width: 60%;
        max-width: none;
    }

    .h-modal-75 {
        width: 75%;
        max-width: none;
    }
}

.h-hover-scale {
    transition: transform .3s ease;
}

.h-hover-scale:hover {
    transition: transform .3s ease;
    transform: scale(1.1);
}

/* screen resolution greater than 1440px */
@media only screen and (min-width: 1440px) {
    .h-widget {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
}

/* screen resolution less than 1440px */
@media only screen and (max-width: 1439px) {
    .h-widget {
        display: flex;
        gap: 20px;
        min-width: 1000px;
    }
}

.h-background-circles {
    background-image: radial-gradient(circle, rgb(52, 16, 83), rgb(229, 229, 247)), repeating-radial-gradient(circle, rgb(52, 16, 83), rgb(52, 16, 83), 10px, transparent 20px, transparent 10px);
    background-blend-mode: multiply;
}

.h-background-wavy {
    background-color: rgba(213, 213, 233, 0.5);
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, rgba(213, 213, 233, 0.5) 15px), repeating-linear-gradient(rgba(68, 76, 247, 0.3333), rgba(68, 76, 247, 0.5));
}

.h-background-arc {
    --s: 15px;
    /* control the size*/
    --c1: #d2b3b3;
    --c2: #1836b0;

    --g: radial-gradient(30% 50% at 30% 100%, #0000 66%, var(--c1) 67% 98%, #0000);
    background:
        var(--g), var(--g) calc(5*var(--s)) calc(3*var(--s)),
        repeating-linear-gradient(90deg, var(--c1) 0 10%, var(--c2) 0 50%);
    background-size: calc(10*var(--s)) calc(6*var(--s));
}

.h-background-overlapping-circles {
    --s: 50px;
    /* control the size*/
    --c1: #f7d2a1;
    --c2: #05057e;

    --_g:
        var(--c1) 0% 5%, var(--c2) 6% 15%, var(--c1) 16% 25%, var(--c2) 26% 35%, var(--c1) 36% 45%,
        var(--c2) 46% 55%, var(--c1) 56% 65%, var(--c2) 66% 75%, var(--c1) 76% 85%, var(--c2) 86% 95%,
        #0000 96%;
    background:
        radial-gradient(50% 50% at 100% 0, var(--_g)),
        radial-gradient(50% 50% at 0 100%, var(--_g)),
        radial-gradient(50% 50%, var(--_g)),
        radial-gradient(50% 50%, var(--_g)) calc(var(--s)/2) calc(var(--s)/2) var(--c1);
    background-size: var(--s) var(--s);
}

.h-aside-navbar {
    display: flex;
    flex-direction: column;
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    transition: all .3s ease-in-out;
    /* background-color: #1e1e2d; */
}

.h-aside-navbar.h-aside-navbar-collapse {
    width: 80px;
    min-width: 80px;
    max-width: 80px;

    & .menu-title {
        display: none;
        opacity: 0;
    }

    & .menu-arrow {
        display: none;
        opacity: 0;
    }

    & .menu-heading {
        display: none;
        opacity: 0;
    }

    & .menu-item.hover.show>.menu-sub-accordion {
        display: none;
        opacity: 0;
    }

    & .btn-label {
        display: none;
        opacity: 0;
    }
}

.h-aside-navbar.h-aside-navbar-collapse:hover {
    width: 250px;
    min-width: 250px;
    max-width: 250px;

    & .menu-title {
        display: initial;
        opacity: 1;
    }

    & .menu-arrow {
        display: flex;
        opacity: 1;
    }

    & .menu-heading {
        display: initial;
        opacity: 1;
    }

    & .menu-item.hover.show>.menu-sub-accordion {
        display: flex;
        opacity: 1;
    }

    & .btn-label {
        display: initial;
        opacity: 1;
    }
}

@media only screen and (width < 768px) {
    .h-aside-navbar {
        transform: translate(-100%, 0px);
        position: absolute;
        z-index: 999;
        height: 100%;
    }
}

.h-cash-flow-wrapper {
    overflow: hidden;
}

@media only screen and (max-width: 1439px) {
    .h-cash-flow-wrapper {
        overflow: auto;
    }
}

.right-arrow:after {
    content: '';
    position: absolute;
    top: 25%;
    left: 90%;
    width: 20px;
    height: 20px;
    background-color: rgb(105, 75, 205);
    opacity: 0.5;
    /* clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%); */
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.bg-forgot-password {
    background-size: 100% 100%;
    background-image: url("../public/assets/media/auth/bg4.jpg");
}

.sticky-column {
    position: absolute;
    right: 0;
    padding-right: 0.75rem !important;
}

@media (width >= 768px) {
    .h-app-sidebar-logo {
        height: var(--kt-app-header-height);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex-shrink: 0;
    }
}

@media (width < 768px) {
    .h-app-sidebar-logo {
        display: none;
    }
}

.h-app-sidebar-toggle {
    box-shadow: var(--kt-app-sidebar-base-toggle-btn-box-shadow) !important;
    background-color: var(--kt-app-sidebar-base-toggle-btn-bg-color) !important;
}

.h-otp-input{
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 10px 0;
}

.h-otp-input input{
    width: 30px;
    height: 40px;
    border-radius: 10px;
    font-size: 30px;
    border: 1px solid #ddd;
    text-align: center;
}

.h-otp-input input[type = 'number']::-webkit-outer-spin-button, .h-otp-input input[type = 'number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}