.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: Poppins,Helvetica,sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 300px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}